define("@ember/-internals/runtime/lib/system/object_proxy", ["exports", "@ember/-internals/runtime/lib/system/object", "@ember/-internals/runtime/lib/mixins/-proxy"], function (_exports, _object, _proxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    `ObjectProxy` forwards all properties not defined by the proxy itself
    to a proxied `content` object.
  
    ```javascript
    import EmberObject from '@ember/object';
    import ObjectProxy from '@ember/object/proxy';
  
    object = EmberObject.create({
      name: 'Foo'
    });
  
    proxy = ObjectProxy.create({
      content: object
    });
  
    // Access and change existing properties
    proxy.get('name')          // 'Foo'
    proxy.set('name', 'Bar');
    object.get('name')         // 'Bar'
  
    // Create new 'description' property on `object`
    proxy.set('description', 'Foo is a whizboo baz');
    object.get('description')  // 'Foo is a whizboo baz'
    ```
  
    While `content` is unset, setting a property to be delegated will throw an
    Error.
  
    ```javascript
    import ObjectProxy from '@ember/object/proxy';
  
    proxy = ObjectProxy.create({
      content: null,
      flag: null
    });
    proxy.set('flag', true);
    proxy.get('flag');         // true
    proxy.get('foo');          // undefined
    proxy.set('foo', 'data');  // throws Error
    ```
  
    Delegated properties can be bound to and will change when content is updated.
  
    Computed properties on the proxy itself can depend on delegated properties.
  
    ```javascript
    import { computed } from '@ember/object';
    import ObjectProxy from '@ember/object/proxy';
  
    ProxyWithComputedProperty = ObjectProxy.extend({
      fullName: computed('firstName', 'lastName', function() {
        var firstName = this.get('firstName'),
            lastName = this.get('lastName');
        if (firstName && lastName) {
          return firstName + ' ' + lastName;
        }
        return firstName || lastName;
      })
    });
  
    proxy = ProxyWithComputedProperty.create();
  
    proxy.get('fullName');  // undefined
    proxy.set('content', {
      firstName: 'Tom', lastName: 'Dale'
    }); // triggers property change for fullName on proxy
  
    proxy.get('fullName');  // 'Tom Dale'
    ```
  
    @class ObjectProxy
    @extends EmberObject
    @uses Ember.ProxyMixin
    @public
  */
  class ObjectProxy extends _object.default {}

  _exports.default = ObjectProxy;
  ObjectProxy.PrototypeMixin.reopen(_proxy.default);
});